/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Oct 03 2023
 */

import {
  defineStore,
} from 'pinia';
import {
  gptModelNames,
} from 'utilities';
import {
  IConversation,
  IConversationMessage,
  IConversationTemplate,
} from '@/type';

export const useConversationThreadStore = defineStore('conversationThread', {
  state: () => ({
    uuid: '',
    templateId: '',
    freeCreditReplenishDate: undefined as string | undefined,

    selectedUserModel: undefined as typeof gptModelNames[number] | undefined,

    isSocketConnected: false,
    isHistoryLoading: false,
    isReplyLoading: false,

    totalPage: 0,
    sentMessageCountAfterMount: 0,

    messages: [] as IConversationMessage[],
    bubbleMessage: [] as IConversationTemplate['bubbleMessage'],
    inputFormats: [] as IConversationTemplate['inputFormats'],
    outputFormats: [] as IConversationTemplate['outputFormats'],

    selectedInputFormat: undefined as IConversation['inputFormat'],
    selectedOutputFormat: undefined as IConversation['outputFormat'],
    selectedTemplate: undefined as IConversationTemplate | undefined,
    streamingConversation: undefined as IConversation | undefined,
  }),
  persist: {
    storage: window.sessionStorage,
  },
});
