import {
  defineStore,
} from 'pinia';

export const useUtilStore = defineStore('util', {
  state: () => ({
    theme: '',
    translationLanguage: 'English',
  }),
  persist: {
    storage: localStorage,
  },
});
