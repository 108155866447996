import axios from 'axios';
import {
  defineStore,
} from 'pinia';
import {
  useAuthStore,
} from './auth';
import {
  handleAxiosError,
} from '@/helpers';
import {
  IConversationTemplate,
} from '@/type';

export const useConversationTemplateStore = defineStore('conversationTemplate', {

  state: () => ({
    // shows all the templates, used in the overlay.
    allTemplates: [] as IConversationTemplate[],
    // shows only itemsPerPage number of templates, used in the dashboard body over the textarea.
    dashboardTemplates: [] as IConversationTemplate[],
    totalData: 0,
    totalPages: 1,
    currentPage: 1,
  }),

  getters: {
    // number of items shown in the circular array in the dashboard body over the textarea
    itemsPerPage: (state) => {
      const width = window.innerWidth;

      if (width <= 640) {
        return 2;
      }

      return width <= 768 ? 3 : 4;
    },
    shownTemplates: (state) => {
      const authStore = useAuthStore();
      const favoriteTemplateIds = authStore.user?.favoriteTemplates || [];
      const hiddenTemplateIds = authStore.user?.hiddenTemplates || [];

      const regularTemplates: IConversationTemplate[] = [];
      let shownTemplates: IConversationTemplate[] = [];

      for (const template of state.allTemplates) {
        const { _id } = template;
        if (hiddenTemplateIds.includes(_id)) {
          continue;
        }

        if (favoriteTemplateIds.includes(_id)) {
          shownTemplates.push(template);
        } else {
          regularTemplates.push(template);
        }
      }

      shownTemplates = [...shownTemplates, ...regularTemplates];

      return shownTemplates;
    },
    hiddenTemplates: (state) => {
      const authStore = useAuthStore();
      const hiddenTemplateIds = authStore.user?.hiddenTemplates || [];

      let hiddenTemplates: IConversationTemplate[] = [];

      hiddenTemplates = state.allTemplates.filter(
        (template) => hiddenTemplateIds.includes(template._id),
      );

      return hiddenTemplates;
    },
  },

  actions: {
    async getConversationTemplates(token: string) {
      if (!token) {
        this.allTemplates = [];
        this.totalData = 0;
        return;
      }

      try {
        const conversationTemplateResponse = await axios.get<{ conversationTemplates: IConversationTemplate[] }>('/conversation-templates');
        this.allTemplates = conversationTemplateResponse.data.conversationTemplates;
        this.totalData = this.allTemplates.length;
      } catch (e: any) {
        handleAxiosError(e);
      }
    },

    setCircularTemplates() {
      this.totalPages = Math.ceil(this.totalData / this.itemsPerPage);
      this.dashboardTemplates = this.shownTemplates.slice(0, this.itemsPerPage);
    },

    updateItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.dashboardTemplates = this.shownTemplates.slice(start, end);
    },

    next() {
      this.currentPage = (this.currentPage % (Math.ceil(this.shownTemplates.length / this.itemsPerPage))) + 1;
      this.updateItems();
    },

    previous() {
      this.currentPage = this.currentPage === 1
        ? Math.ceil(this.shownTemplates.length / this.itemsPerPage)
        : this.currentPage - 1;
      this.updateItems();
    },

  },
  persist: {
    storage: window.sessionStorage,
  },
});
