/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Fri Aug 05 2022
 */

import axios from 'axios';
import {
  defineStore,
} from 'pinia';
import {
  IClientSettings,
} from 'types';
import {
  handleAxiosError,
} from '@/helpers';

export const useBackendStore = defineStore('backend', {
  state: () => ({
    siteIsActive: true,
    templateIdForTranslation: undefined as string | undefined,
    templateIdForYtSummary: undefined as string | undefined,
  }),
  actions: {

    async initOnAppMount(): Promise<void> {
      const clientSettingsPromise = async (): Promise<void> => {
        try {
          const response = await axios.get<{settings: IClientSettings}>('/settings/client');
          const {
            templateIdForTranslation,
            templateIdForYtSummary,
          } = response.data.settings;

          this.templateIdForTranslation = templateIdForTranslation;
          this.templateIdForYtSummary = templateIdForYtSummary;
        } catch (e: any) {
          handleAxiosError(e);
        }
      };

      await Promise.all([clientSettingsPromise()]);
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
