import businessProposalGif from '@/assets/landing-page/templates/gif/Business-Proposal.gif';
import writeAComicGif from '@/assets/landing-page/templates/gif/Comic.gif';
import formatInformationGif from '@/assets/landing-page/templates/gif/Format-Information.gif';
import languageLearningGif from '@/assets/landing-page/templates/gif/Language.gif';
import openEndedChatGif from '@/assets/landing-page/templates/gif/Open-Ended-Chat.gif';
import productReviewGif from '@/assets/landing-page/templates/gif/Product-Review.gif';
import cookBiriyaniGif from '@/assets/landing-page/templates/gif/recipe.gif';
import writeAPoemGif from '@/assets/landing-page/templates/gif/Write-a-poem.gif';
import codeInAnyLanguageGif from '@/assets/landing-page/templates/gif/Write-Any-Code.gif';
import youtubeSummaryGif from '@/assets/landing-page/templates/gif/Youtube.gif';
import businessProposalPng from '@/assets/landing-page/templates/png/Business-Proposal.png';
import codeInAnyLanguagePng from '@/assets/landing-page/templates/png/Code-in-Any-Language.png';
import cookBiriyaniPng from '@/assets/landing-page/templates/png/Cook-biriyani.png';
import formatInformationPng from '@/assets/landing-page/templates/png/Format-Information.png';
import languageLearningPng from '@/assets/landing-page/templates/png/Language-Learning.png';
import openEndedChatPng from '@/assets/landing-page/templates/png/Open-Ended-Chat.png';
import productReviewPng from '@/assets/landing-page/templates/png/Product-Review.png';
import writeAComicPng from '@/assets/landing-page/templates/png/Write-a-Comic.png';
import writeAPoemPng from '@/assets/landing-page/templates/png/Write-a-Poem.png';
import youtubeSummaryPng from '@/assets/landing-page/templates/png/Youtube-Summary.png';

export const essayTopics = [
  {
    question: 'Write an essay about global environment',
    response: 'The global environment is facing numerous challenges, including climate change, pollution, and habitat destruction. These problems are affecting the health and well-being of people, animals, and ecosystems around the world. To address these challenges, we need to work together to reduce our environmental footprint, protect natural resources, and promote sustainable development. By taking action now, we can create a better future for ourselves and for generations to come.',
  },
  {
    question: 'Write a cover letter for applying Assistance Manager',
    response: `Dear [Hiring Manager's Name],</br></br>

    I am writing to express my interest in the Assistant Manager position at [Company Name]. With my experience in [relevant field], I am confident ... </br></br>

    At [Previous Company], I was responsible for [specific job duties and accomplishments]. Through this experience, I developed strong ...</br></br>

    I am particularly excited about the opportunity to work at [Company Name] because of ...</br></br>

    Thank you for considering my application. I look forward to the opportunity to discuss my qualifications further.</br></br></br>

    Sincerely,
    [Your Name]
  `,
  },
];

export const interviewTopics = [{
  question: 'How do you prioritize your workload when you have multiple tasks to complete in a short period of time?',
  response: 'When I have multiple tasks to complete in a short period of time, I start by identifying the most urgent and important tasks.<br> Then, I prioritize them based on their deadlines and level of importance. </br> I make sure to break down large tasks into smaller, more manageable ones, and I stay focused and organized to ensure that I meet all my deadlines.',
}, {
  question: 'Can you give an example of a time when you had to handle a difficult customer or client, and how you resolved the situation?',
  response: 'Once, I had a client who was unhappy with the service they received. <br>I listened to their concerns, apologized for any inconvenience, and offered a solution that met their needs. </br>By being empathetic, communicative, and finding a mutually agreeable solution, I was able to resolve the situation to the client\'s satisfaction.',
},
{
  question: 'How do you prioritize your workload in a fast-paced AI development environment?',
  response: 'In a dynamic AI development scenario, prioritizing tasks is crucial. I commence by identifying the tasks with imminent deadlines and high impact. Employing agile methodologies and leveraging project management tools assist in tracking progress and ensuring timely delivery. Balancing between bug-fixing, feature development, and research in AI technologies like ChatGPT, requires a well-thought-out strategy to manage time efficiently while fostering innovation and quality.',
}];

export const developerTopics = [{
  question: 'Describe the factory pattern in the context of a chatbot development',
  response: 'The factory pattern, a cornerstone in object-oriented programming, is significantly beneficial in chatbot development. It facilitates the creation of chatbot objects, without detailing the exact class of object that will be created. This abstraction is instrumental when dealing with multiple chatbot frameworks like Dialogflow, Microsoft Bot Framework, or custom-built solutions. It promotes a clean, organized codebase, enabling developers to instantiate and manage chatbots in a way that is flexible, extensible, and conducive to maintenance.',
}, {
  question: 'Explain inheritance in object-oriented programming',
  response: 'Inheritance in object-oriented programming allows a class to inherit properties and behavior from another class, referred to as the superclass or parent class. The subclass or child class can then use these inherited properties and behaviors as its own or override them with its own implementation. This helps to promote code reuse and simplifies code maintenance...',
}];

export const teacherTopics = [
  {
    question: 'Can you create an English lesson plan for second-grade students that focuses on developing their reading comprehension skills?',
    response: 'For a reading comprehension lesson plan for second-grade students, I would start with selecting a grade-appropriate text and develop a series of pre-reading, during-reading, and post-reading activities. Pre-reading activities could include activating prior knowledge and setting reading goals, during-reading activities could include guided reading and questioning, and post-reading activities could include summarizing, retelling, and reflection. The lesson plan should also include differentiated instruction strategies to meet the diverse learning needs of the students.',
  },
  {
    question: 'How do you measure and evaluate student progress in your classroom? Can you give an example of a specific assessment you use?',
    response: 'As a teacher, I use a variety of strategies to select and create educational materials that are engaging and effective for my students. Some of these strategies include identifying learning objectives and matching materials to those objectives, using a mix of different media (such as text, images, and videos), incorporating interactive activities, and seeking feedback from students to improve the materials over time. Ultimately, my goal is to create materials that are both informative and enjoyable, and that help my students develop the skills and knowledge they need to succeed.',
  },
];

export const sectionData = [
  {
    title: 'WRITING',
    subTitle: 'Email, Poem, Story, Essay, Song & More',
    description: 'By using AI Mate to assist you in writing in various topics, you can improve your writing skills, save time, and create high-quality content that resonates with your readers.',
  },
  {
    title: 'BUSINESS ASSIST',
    subTitle: 'Business proposal, campaign, or newsletter',
    description: 'Whether you\'re writing a business proposal, creating a marketing campaign, or crafting a company newsletter, AI Mate can help you communicate your ideas effectively and professionally',
  },
  {
    title: 'INTERVIEWER',
    subTitle: '',
    description: 'As an interviewer, you may need to write job descriptions, interview questions, or even rejection letters. AI Mate can help you craft professional and effective communication that represents your company well and helps you attract the best candidates for the job.',
  },
  {
    title: 'DEVELOPER',
    subTitle: '',
    description: 'As a developer, you may need to write technical documentation, code comments, or even blog posts to share your knowledge with others. AI Mate can help you create clear and concise content that explains complex concepts in a way that\'s easy for others to understand.',
  },
  {
    title: 'TEACHER',
    subTitle: '',
    description: 'As a teacher, you may need to write lesson plans, feedback for students, or even educational materials. AI Mate can help you create engaging and effective content that inspires your students and helps them achieve their learning objectives',
  },
];

export const allTemplates = [
  {
    title: 'Youtube Summary',
    description: 'Get quick summaries of any YouTube video! Save time by getting the main points without watching the whole video. Perfect for busy professionals, students, and anyone on the go. Dive into the key content and stay informed with ease.',
    pngImgSrc: youtubeSummaryPng,
    gifImgSrc: youtubeSummaryGif,
  },
  {
    title: 'Format Information',
    description: 'Format information in HTML, XML or markdown! Easily convert your data into structured formats that are readable and maintainable. This template helps you present your content in a professional and organized manner.',
    pngImgSrc: formatInformationPng,
    gifImgSrc: formatInformationGif,
  },
  {
    title: 'Open Ended Chat',
    description: 'Have an open ended conversation with our AI. This template allows for a free-flowing dialogue where you can ask questions, get answers, and explore various topics seamlessly. Ideal for brainstorming and spontaneous interactions.',
    pngImgSrc: openEndedChatPng,
    gifImgSrc: openEndedChatGif,
  },
  {
    title: 'Language Learning',
    description: 'Learn English, French, German, and More! Our language learning template offers interactive lessons and exercises to help you master new languages quickly and effectively. Perfect for beginners and advanced learners alike.',
    pngImgSrc: languageLearningPng,
    gifImgSrc: languageLearningGif,
  },
  {
    title: 'Code in Any Language',
    description: 'Ask Ai Mate to write code! Whether you need a snippet or a full program, this template helps you generate code in various programming languages. It’s a great tool for developers looking to save time and improve productivity.',
    pngImgSrc: codeInAnyLanguagePng,
    gifImgSrc: codeInAnyLanguageGif,
  },
  {
    title: 'Write a Comic',
    description: 'Create your superhero comic with ease. This template guides you through the process of writing and illustrating your own comic book, helping you bring your stories and characters to life. Perfect for aspiring comic creators.',
    pngImgSrc: writeAComicPng,
    gifImgSrc: writeAComicGif,
  },
  {
    title: 'Product Review',
    description: 'Not sure what to write about your product? Ask me! This template assists you in crafting detailed and persuasive product reviews that highlight key features and benefits. Ideal for marketers and reviewers looking to engage their audience.',
    pngImgSrc: productReviewPng,
    gifImgSrc: productReviewGif,
  },
  {
    title: 'Business Proposal',
    description: 'Not sure where to start? Ask me and I will show you. Our business proposal template helps you outline and present your ideas clearly and effectively, making it easier to secure approvals and investments.',
    pngImgSrc: businessProposalPng,
    gifImgSrc: businessProposalGif,
  },
  {
    title: 'Cook Biriyani',
    description: 'Ask AI Mate how to cook biriyani. This template provides you with a step-by-step recipe and cooking tips to make delicious biriyani at home. Perfect for food enthusiasts and home chefs looking to try something new.',
    pngImgSrc: cookBiriyaniPng,
    gifImgSrc: cookBiriyaniGif,
  },
  {
    title: 'Write a Poem',
    description: 'Write a poem about any topic. This template offers prompts and guidance to help you create beautiful and expressive poetry. Whether you’re a seasoned poet or a beginner, you’ll find inspiration and support here.',
    pngImgSrc: writeAPoemPng,
    gifImgSrc: writeAPoemGif,
  },
];
