/* eslint-disable import/order */
import {
  RouteRecordRaw,
  createRouter,
  createWebHistory,
} from 'vue-router';
import {
  CONFIG,
  CONSTANTS,
  IS_DEVELOPMENT,
} from '@/helpers';
import {
  useStore,
} from '@/store';

// REFERENCE: https://router.vuejs.org/guide/advanced/lazy-loading.html

/**
 * NOTE: For every new webpackChunkName, that chunk name should be added to `LazyScript.vue` file
 */

// Common
const LandingPage = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "home" */
  '@/views/Common/LandingPage.vue'
);
const PrivacyPolicy = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "privacy-policy" */
  '@/views/Common/PrivacyPolicy.vue'
);
const TermsOfService = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "terms-of-service" */
  '@/views/Common/TermsOfService.vue'
);
const RefundPolicy = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "refund-policy" */
  '@/views/Common/RefundPolicy.vue'
);
const Faq = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "faq" */
  '@/views/Common/FaqSection.vue'
);

// Auth
const AuthView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/AuthView.vue'
);
const LoginView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/LoginView.vue'
);
const SignupView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/SignupView.vue'
);
const ResetPassword = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/ResetPassword.vue'
);
const VerifyResetPassword = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/VerifyResetPassword.vue'
);
const ActivateEmail = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/ActivateEmail.vue'
);
const VerifyEmail = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/VerifyEmail.vue'
);
const GoogleLogin = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "google-login" */
  '@/views/Auth/GoogleLogin.vue'
);
const AppLogin = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "app-login" */
  '@/views/Auth/AppLogin.vue'
);

// End User
const ContactView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-contact" */
  '@/views/EndUser/ContactView.vue'
);
const DashboardView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-dashboard" */
  '@/views/EndUser/DashboardView.vue'
);
const UserView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "end-user-view" */
  '@/views/EndUser/UserView.vue'
);
const AccountView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "end-user-view" */
  '@/views/EndUser/AccountView.vue'
);
const LinkWithAppView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "end-user-view" */
  '@/views/EndUser/LinkWithApp.vue'
);
const SubscriptionView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "end-user-view" */
  '@/views/EndUser/SubscriptionView.vue'
);

const isGuest = {
  meta: {
    isGuest: true,
  },
};

const isCommon = {
  meta: {
    isCommon: true,
  },
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: CONSTANTS.TITLES.HOME_ROUTE,
    component: LandingPage,
    meta: {
      title: CONSTANTS.TITLES.HOME_ROUTE,
      ...isCommon.meta,
    },
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthView,
    children: [
      {
        path: 'login',
        name: 'Log in',
        component: LoginView,
        meta: {
          title: 'Log in',
          ...isGuest.meta,
        },
      },
      {
        path: 'signup',
        name: 'Sign up',
        component: SignupView,
        meta: {
          title: 'Sign up',
          ...isGuest.meta,
        },
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          title: 'Reset Password',
          ...isGuest.meta,
        },
      },
      {
        path: 'verify-reset-password/:id',
        name: 'VerifyResetPassword',
        component: VerifyResetPassword,
        meta: {
          title: 'Verify Reset Password',
          ...isGuest.meta,
        },
      },
      {
        path: 'activate-email',
        name: 'ActivateEmail',
        component: ActivateEmail,
        meta: {
          title: 'Activate Email',
          ...isGuest.meta,
        },
      },
      {
        path: 'verify-email/:token',
        name: 'VerifyEmail',
        component: VerifyEmail,
        meta: {
          title: 'Verify Email',
          ...isGuest.meta,
        },
      },
    ],
  },
  {
    path: '/login/google',
    name: 'GoogleLogin',
    component: GoogleLogin,
    meta: {
      title: 'Google Login',
      ...isGuest.meta,
    },
  },
  {
    path: '/login/app',
    name: 'AppLogin',
    component: AppLogin,
    meta: {
      title: 'App Login',
      ...isGuest.meta,
    },
  },
  {
    path: '/privacy-policy.html',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy',
      ...isCommon.meta,
    },
  },
  {
    path: '/terms.html',
    name: 'TermsOfService',
    component: TermsOfService,
    meta: {
      title: 'Terms of Service',
      ...isCommon.meta,
    },
  },
  {
    path: '/refund-policy',
    name: 'RefundPolicy',
    component: RefundPolicy,
    meta: {
      title: 'Refund Policy',
      ...isCommon.meta,
    },
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: {
      title: 'Faq',
      ...isCommon.meta,
    },
  },
  {
    path: '/dashboard/chat/:uuid',
    name: 'Dashboard',
    component: DashboardView,
    meta: {
      title: 'Dashboard',
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView,
    meta: {
      title: 'Contact',
    },
  },
  {
    path: '/user',
    name: 'User',
    component: UserView,
    children: [
      {
        path: 'account',
        name: 'Account',
        component: AccountView,
        meta: {
          title: 'Account',
        },
      },
      {
        path: 'link-with-app',
        name: 'LinkWithApp',
        component: LinkWithAppView,
        meta: {
          title: 'Link With App',
        },
      },
      {
        path: 'subscription',
        name: 'Subscription',
        component: SubscriptionView,
        meta: {
          title: 'Subscription',
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash.length > 1) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
            });
            resolve();
          } else {
            resolve({
              top: 0,
            });
          }
        }, 500);
      });
    } if (savedPosition) {
      return savedPosition;
    }
    return {
      top: 0,
    };
  },
});

// ensuring user load from local storage attempted before routing
router.beforeEach(async (to, from, next) => {
  const prevRoutePath = from.fullPath;
  useStore.app.isLoading = true;

  await useStore.app.init(prevRoutePath);
  next();
});

router.afterEach((to, from, failure) => {
  useStore.app.isLoading = false;
});

router.beforeResolve((to, from, next) => {
  const authStore = useStore.auth;

  let titleSuffix = authStore.user && IS_DEVELOPMENT
    ? ` | ${authStore.user.firstName}`
    : '';

  if (to.meta?.title) {
    titleSuffix = ` - ${to.meta?.title}${titleSuffix}`;
  }
  document.title = `${CONFIG.appName}${titleSuffix}`;

  if (!authStore.user && !to.meta?.isGuest && !to.meta?.isCommon) {
    next(
      `/auth/${
        from.meta?.isCommon ? 'signup' : 'login'
      }?redirect=${encodeURIComponent(to.fullPath)}`,
    );
    return;
  }

  const shouldRedirectToMemberHome = to.meta?.isGuest || to.name === CONSTANTS.TITLES.HOME_ROUTE;

  if (authStore.user?.role === 'end-user' && shouldRedirectToMemberHome) {
    next(CONSTANTS.ROUTES.MEMBER_HOME);
    return;
  }

  next();
});

export { routes };

export default router;
