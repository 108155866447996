/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Jan 03 2023
 */

<template>
  <div class="flex justify-center">
    <span class="text-sm font-bold sm:text-lg">
      Developed by
    </span>
    <a
      class="pl-4"
      href="https://nerddevs.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        :src="require('@/assets/site-assets/nerddevs_orange_logo.png')"
        alt="nerddevs-logo"
        class="h-[24px] w-[120px] sm:h-[34px] sm:w-[172px]"
      >
    </a>
  </div>
</template>
