/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Thu Aug 04 2022
 */

import {
  useAppStore,
} from '@/store/app';
import {
  useAuthStore,
} from '@/store/auth';
import {
  useBackendStore,
} from '@/store/backend';
import {
  useConversationHistoryStore,
} from '@/store/conversationHistory';
import {
  useConversationTemplateStore,
} from '@/store/conversationTemplate';
import {
  useConversationThreadStore,
} from '@/store/conversationThread';
import {
  useNavStore,
} from '@/store/nav';
import {
  useSubscriptionStore,
} from '@/store/subscription';
import {
  useUtilStore,
} from '@/store/util';

export interface IAppUseStore {
  app: ReturnType<typeof useAppStore>;
  backend: ReturnType<typeof useBackendStore>;
  nav: ReturnType<typeof useNavStore>;
  util: ReturnType<typeof useUtilStore>;
  template: ReturnType<typeof useConversationTemplateStore>;

  auth: ReturnType<typeof useAuthStore>;
  subscription: ReturnType<typeof useSubscriptionStore>;
  conversationThread: ReturnType<typeof useConversationThreadStore>;
  conversationHistory: ReturnType<typeof useConversationHistoryStore>;
}

const useStore = {
} as IAppUseStore;

const registerStore = (): void => {
  useStore.app = useAppStore();
  useStore.backend = useBackendStore();
  useStore.nav = useNavStore();
  useStore.util = useUtilStore();
  useStore.template = useConversationTemplateStore();

  // user specific store
  useStore.auth = useAuthStore();
  useStore.subscription = useSubscriptionStore();
  useStore.conversationThread = useConversationThreadStore();
  useStore.conversationHistory = useConversationHistoryStore();
};

const resetUserSpecificStore = (): void => {
  useAuthStore().$reset();
  useSubscriptionStore().$reset();
  useConversationThreadStore().$reset();
  useConversationHistoryStore().$reset();
};

const resetStore = (): void => {
  resetUserSpecificStore();

  useAppStore().$reset();
  useBackendStore().$reset();
  useNavStore().$reset();
  useConversationTemplateStore().$reset();
};

export {
  resetUserSpecificStore,
  registerStore,
  resetStore,
  useStore,
};

// NOTE: For hot module replacement see https://pinia.vuejs.org/cookbook/hot-module-replacement.html
