export * from './landingPage';
export * from './languages';

export const CONSTANTS = {
  LOGS: {
    INFO: 'log-info',
  } as const,
  ROUTES: {
    GUEST_HOME: '/',
    MEMBER_HOME: '/dashboard/chat/new',
    ACCOUNT_PAGE: '/user/account',
    SUBSCRIPTION_PAGE: '/user/subscription',
  } as const,
  TITLES: {
    HOME_ROUTE: 'Home',
  } as const,
  ROUTE_PREFIX: {
    DASHBOARD: '/dashboard/chat/',
  },
  STORAGE: {
    LOGIN_DATA: 'LOGIN_DATA',
  } as const,
} as const;
