import {
  defineStore,
} from 'pinia';
import {
  IGetConversationsData,
} from '@/type';

export const useConversationHistoryStore = defineStore('conversationHistory', {
  state: () => ({
    conversationList: [] as IGetConversationsData['conversations'],
    currentPage: 1,
    isLoading: false,
    hasMore: true,
    searchQuery: '',
    totalData: 0,
    newChatCountAfterMount: 0,
    hasConversation: false,
  }),
  persist: {
    storage: window.sessionStorage,
  },
});
