/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Fri Aug 05 2022
 */

import axios from 'axios';
import {
  defineStore,
} from 'pinia';
import {
  IWebSubscription,
  IWebSubscriptionProduct,
} from 'types';
import {
  useAuthStore,
} from './auth';
import {
  handleAxiosError,
} from '@/helpers';

export const useSubscriptionStore = defineStore('subscription', {
  state: () => ({
    userWebSubscription: undefined as IWebSubscription | undefined,
    products: [] as IWebSubscriptionProduct[],
    isLoading: {
      paymentMethod: false,
      customer: false,
      checkout: false,
      subscriptionVerify: false,
    },
  }),
  getters: {
    plans: (state) => state.products.map((product) => {
      const { customData } = product;
      const features: string[] = [];
      const tags: string[] = [];

      if (customData) {
        for (const key in customData) {
          const value = customData[key];

          if (key.startsWith('feature')) {
            features.push(value);
          }

          if (key.startsWith('tag')) {
            tags.push(value);
          }
        }
      }

      return {
        ...product,
        features,
        tags,
      };
    }),
    subscriptionInfo(state) {
      const { userWebSubscription, products } = state;

      if (!userWebSubscription) {
        return undefined;
      }

      const {
        billingCycle,
        currencyCode,
        canceledAt,
        currentBillingPeriod,
        firstBilledAt,
        nextBilledAt,
        pausedAt,
        product,
        startedAt,
        status,
        item,
        scheduledChange,
      } = userWebSubscription;

      const { price, trialDates } = item;
      const unitPrice = price?.unitPrice;

      return {
        billingCycle,
        currencyCode,
        canceledAt,
        currentBillingPeriod,
        firstBilledAt,
        nextBilledAt,
        pausedAt,
        product: products.find((p) => p.id === product?.id) || product,
        startedAt,
        status,
        trialDates,
        price,
        unitPrice,
        scheduledChange,
      };
    },
    isSubscriptionExpired(): boolean {
      if (!this.subscriptionInfo) {
        return false;
      }

      const { status, nextBilledAt } = this.subscriptionInfo;

      if (status !== 'past_due' || !nextBilledAt) {
        return false;
      }

      const gracePeriodDate = new Date(nextBilledAt);

      gracePeriodDate.setDate(gracePeriodDate.getDate() + 3);

      return new Date() < gracePeriodDate;
    },
  },
  actions: {
    async resetSubscriptionProducts(token: string): Promise<void> {
      if (!token) {
        this.products.splice(0);
        return;
      }

      try {
        const response = await axios.get<{docs: IWebSubscriptionProduct[]}>(
          '/web/subscriptions/products',
        );

        this.products = response.data.docs;
      } catch (e: any) {
        handleAxiosError(e);
      }
    },
    async resetUserSubscription(token: string): Promise<void> {
      if (!token) {
        this.userWebSubscription = undefined;
        return;
      }

      try {
        const response = await axios.get<{doc: IWebSubscription | null}>('/web/subscriptions');
        this.userWebSubscription = response.data.doc || undefined;
      } catch (e: any) {
        handleAxiosError(e);
      }
    },
    async initOnAuthChange(): Promise<void> {
      const { token } = useAuthStore();

      await Promise.all([
        this.resetUserSubscription(token),
        this.resetSubscriptionProducts(token),
      ]);
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
